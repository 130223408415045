html,
body {
  height: 100%;
}

body {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  margin: 0;
  font-family: "Montserrat", sans-serif;
  /* background-color: rgb(208, 226, 255); */
  overscroll-behavior: none;
  background: linear-gradient(90deg, #a0d2eb, #c9e8ff);
}

footer {
  margin-top: auto;
  /* Ensures footer is pushed to the bottom */
}

footer .container-fluid {
  padding-left: 15px;
  padding-right: 15px;
}

.footer-logo {
  max-height: 35px;
}

@media (max-width: 768px) {
  .footer .container-fluid {
    padding-left: 15px;
    padding-right: 15px;
  }

  .footer-logo {
    max-height: 35px;
  }

  .footer .row {
    flex-direction: column;
    text-align: center;
  }
}

/* Container styling */
/* .container {
  width: 100%;
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
} */