/* Increase link size for small viewports */
@media (max-width: 768px) {
  .navbar-nav .nav-link {
    font-size: 1.25rem;
    /* Increase font size */
    padding: 15px;
    /* Add padding for bigger clickable area */
  }

  /* Align navbar links to the left in offcanvas menu */
  .offcanvas-body {
    text-align: left;
    /* Align text to the left */
  }

  /* Ensure that the offcanvas menu is flush with the left */
  .offcanvas {
    padding-left: 0;
    /* Remove default padding */
  }

  /* Optional: Add a bit of margin to the links */
  .navbar-nav .nav-item {
    margin-bottom: 10px;
    /* Spacing between links */
  }
}

.navbar {
  background: linear-gradient(90deg, #a0d2eb, #c9e8ff);
  max-height: 60px;
}

.navbar-brand {
  font-weight: bold;
}

.nav-link {
  color: #000;
}

.nav-link:hover {
  color: #007bff;
}

.logo {
  max-width: 70px;
  border-radius: 0.3rem;
}

a {
  text-decoration: none;
}

/* Navbar customization */

.navbar a {
  color: #242424;
  padding: 10px;
}

.navbar a:hover {
  color: #007bff;
}

.navbar a.active {
  color: #007bff;
}

/* Offcanvas should be full width on small screens */
.offcanvas {
  width: 100%;
  /* Fullscreen on mobile */
}

.offcanvas-body {
  overflow-y: hidden;
  /* Disable scrolling inside the off-canvas */
}

/* Ensure the content fills the page */
.flex-grow-1 {
  padding: 20px;
}

@media (max-width: 768px) {
  body {
    font-size: 0.8em;
  }

  h1 {
    font-size: 1.75rem;
  }

  table {
    font-size: 0.85em;
  }

  .table-responsive {
    overflow-x: auto;
  }
}

.dropdown-menu {
  right: 0;
  left: auto;
}

.dropdown-item {
  cursor: pointer;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}